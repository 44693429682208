import { Tabs, TabsNav } from '../Tabs';
import { portfolioTabs, settingsTabs } from '../../configs/portfolio';
import * as Styles from '../../routes/portfolio/styles/Portfolio';
import { track } from '../../constants/datadog';
import { EVENT } from '../../constants/events';
import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import clsx from 'clsx';
import { isPresenterMode } from '../../utils/isPresenterMode';

const ALLOWED_SETTINGS_TABS = [
  settingsTabs.GENERAL,
  settingsTabs.FUNNEL,
  settingsTabs.IMPACT_GROUPS,
  settingsTabs.CONTENT_SETS,
];

interface Props {
  portfolioId: number;
  sidebarWidth: string;
}

export const PortfolioSettingsTabs = ({ portfolioId, sidebarWidth }: Props) => {
  const [activeTab] = useQueryParam('activeTab', StringParam);
  const [activeSettingsTab, setActiveSettingsTab] = useQueryParam('activeSettingsTab', StringParam);

  useEffect(() => {
    if (activeTab === portfolioTabs.SETTINGS && !activeSettingsTab) {
      setActiveSettingsTab(settingsTabs.GENERAL);
    }
  }, [activeTab]);

  const handleSettingsTabChange = (tab: string) => {
    setActiveSettingsTab(tab);
    track(EVENT.PORTFOLIO_SETTINGS_TAB_CHANGE(tab), {
      portfolioId,
    });
  };

  return activeTab === portfolioTabs.SETTINGS ? (
    <div
      className={clsx('flex items-center justify-between border-b border-neutral-100 bg-neutral-50 px-8')}
      style={{ marginLeft: isPresenterMode() ? 0 : sidebarWidth, transition: 'margin-left 0.25s ease-in-out' }}
    >
      <div>
        <Tabs
          defaultTab={ALLOWED_SETTINGS_TABS.includes(activeSettingsTab) ? activeSettingsTab : settingsTabs.GENERAL}
          controlledTab={ALLOWED_SETTINGS_TABS.includes(activeSettingsTab) ? activeSettingsTab : settingsTabs.GENERAL}
        >
          <TabsNav
            size="small"
            tab={settingsTabs.GENERAL}
            component={Styles.NavTabStyled}
            onClick={() => handleSettingsTabChange(settingsTabs.GENERAL)}
          >
            General
          </TabsNav>
          <TabsNav
            size="small"
            tab={settingsTabs.FUNNEL}
            component={Styles.NavTabStyled}
            onClick={() => handleSettingsTabChange(settingsTabs.FUNNEL)}
          >
            Funnel
          </TabsNav>
          <TabsNav
            size="small"
            tab={settingsTabs.IMPACT_GROUPS}
            component={Styles.NavTabStyled}
            onClick={() => handleSettingsTabChange(settingsTabs.IMPACT_GROUPS)}
          >
            Impact groups
          </TabsNav>
        </Tabs>
      </div>
    </div>
  ) : null;
};
