import React, { useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import IndividualFunnelEdit from './components/PortfolioEditFunnel/IndividualFunnelEdit';
import { useFunnelStepsV2Query } from '../../graphql/funnelSteps/queryFunnelSteps';
import { useRouteMatch } from 'react-router';
import { extractFunnelSteps } from '../../utils/extractFunnelSteps';
import dayjs from 'dayjs';
import {
  NexoyaFunnelStepType,
  NexoyaFunnelStepV2,
  NexoyaUpsertPortfolioFunnelStepsMutationFunnelStepInput,
} from '../../types';
import { useUpsertPortfolioFunnelSteps } from '../../graphql/funnelSteps/mutationUpsertPortfolioFunnelSteps';
import { toast } from 'sonner';
import { track } from '../../constants/datadog';
import { EVENT } from '../../constants/events';
import { useTeam } from '../../context/TeamProvider';
import { ConfirmationDialog } from './components/PortfolioEditFunnel/ConfirmationDialog';
import { useDialogState } from '../../components/Dialog';
import { isEqual } from 'lodash';
import { useUpdateFunnelStepTarget } from '../../graphql/funnelSteps/mutationUpdateFunnelStepTarget';
import { usePortfolio } from '../../context/PortfolioProvider';

const DRAG_TYPE = 'FUNNEL_STEP';

export function PortfolioEditFunnel() {
  const match = useRouteMatch();
  const portfolioId = parseInt(match.params.portfolioID, 10);

  const { teamId } = useTeam();

  const { data: funnelStepsData, loading } = useFunnelStepsV2Query({
    portfolioId,
    onCompleted: (data) => {
      const { otherFunnelSteps: initialFunnelSteps } = extractFunnelSteps(data?.portfolioV2?.funnelSteps);
      setFunnelSteps(initialFunnelSteps);
    },
  });

  const { otherFunnelSteps: initialFunnelSteps } = extractFunnelSteps(funnelStepsData?.portfolioV2?.funnelSteps);
  const {
    portfolioV2Info: {
      meta: { data: portfolioMetaData },
    },
  } = usePortfolio();

  const defaultTargetFunnelStepId = portfolioMetaData?.defaultOptimizationTarget?.funnelStepId;

  const [upsertPortfolioFunnelSteps, { loading: upsertLoading }] = useUpsertPortfolioFunnelSteps({ portfolioId });
  const [updateFunnelStepTarget, { loading: updateFunnelTargetLoading }] = useUpdateFunnelStepTarget({ portfolioId });

  const [funnelSteps, setFunnelSteps] = useState<NexoyaFunnelStepV2[]>([]);
  const [selectedFunnelStepTargetId, setSelectedFunnelStepTargetId] = useState(defaultTargetFunnelStepId);

  const { isOpen: isApplyOpen, openDialog: openApplyDialog, closeDialog: closeApplyDialog } = useDialogState();
  const { isOpen: isDiscardOpen, openDialog: openDiscardDialog, closeDialog: closeDiscardDialog } = useDialogState();

  const moveFunnelStep = useCallback((dragIndex, hoverIndex) => {
    setFunnelSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      const [movedStep] = updatedSteps.splice(dragIndex, 1);
      updatedSteps.splice(hoverIndex, 0, movedStep);
      return updatedSteps;
    });
  }, []);

  const addFunnelStep = (newFunnelStep: { title: string; type: NexoyaFunnelStepType; position: number }) => {
    setFunnelSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      // Insert the new funnel step at the specified position
      updatedSteps.splice(newFunnelStep.position, 0, {
        ...newFunnelStep,
        funnelStepId: -dayjs().unix(), // Temporary negative ID until backend sync
      });
      return updatedSteps.map((step, index) => ({
        ...step,
        position: index + 1, // Recalculate positions to ensure consistency
      }));
    });
  };

  const deleteFunnelStep = (index: number) => {
    setFunnelSteps((prevSteps) => {
      const updatedSteps = [...prevSteps];
      updatedSteps.splice(index, 1); // Remove the step at the specified index
      return updatedSteps.map((step, newIndex) => ({
        ...step,
        position: newIndex + 1, // Recalculate positions
      }));
    });
  };

  const [, dropRef] = useDrop({
    accept: DRAG_TYPE,
    hover(item: any, monitor) {
      if (!monitor.isOver()) return;

      const dragIndex = item.index;
      const hoverIndex = funnelSteps.findIndex((_step, i) => i === item.index);

      if (dragIndex !== hoverIndex && hoverIndex >= 0) {
        moveFunnelStep(dragIndex, hoverIndex);
        item.index = hoverIndex; // Update the dragged item's index
      }
    },
  });

  const handleSubmit = () => {
    const funnelStepsToUpsert: NexoyaUpsertPortfolioFunnelStepsMutationFunnelStepInput[] = funnelSteps.map((step) => ({
      funnelStepId: step.funnelStepId >= 0 ? step.funnelStepId : null,
      title: step.title,
      type: step.type,
    }));

    const promises = [];

    // Include the upsert mutation
    promises.push(
      upsertPortfolioFunnelSteps({
        variables: {
          teamId,
          portfolioId,
          funnelSteps: funnelStepsToUpsert,
        },
      }),
    );

    // Check if the target step has changed
    if (selectedFunnelStepTargetId !== defaultTargetFunnelStepId) {
      promises.push(
        updateFunnelStepTarget({
          variables: {
            teamId,
            portfolioId,
            funnelStepId: selectedFunnelStepTargetId,
          },
        }),
      );
    }

    Promise.all(promises)
      .then(() => {
        toast.success('Funnel steps updated successfully');
        closeApplyDialog();
        track(EVENT.FUNNEL_STEP_EDIT);
      })
      .catch(() => {
        toast.error('Failed to update funnel steps');
      });
  };

  return (
    <div ref={dropRef}>
      <div className="mb-8 flex w-full flex-row items-end justify-between">
        <div>
          <Typography variant="h2">Funnel</Typography>
          <Typography variant="subtitle">Manage and modify the funnel setup in your portfolio.</Typography>
        </div>
        <div className="flex h-fit gap-4">
          <Button
            variant="contained"
            onClick={openDiscardDialog}
            disabled={
              upsertLoading ||
              loading ||
              (isEqual(defaultTargetFunnelStepId, selectedFunnelStepTargetId) &&
                isEqual(initialFunnelSteps, funnelSteps))
            }
          >
            Discard changes
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              upsertLoading ||
              loading ||
              (isEqual(defaultTargetFunnelStepId, selectedFunnelStepTargetId) &&
                isEqual(initialFunnelSteps, funnelSteps))
            }
            onClick={openApplyDialog}
          >
            Apply changes
          </Button>
        </div>
      </div>
      {funnelSteps.map((funnelStep, index) => (
        <IndividualFunnelEdit
          index={index}
          funnelStep={funnelStep}
          key={funnelStep.funnelStepId}
          lastIndex={index === funnelSteps.length - 1}
          addFunnelStep={addFunnelStep}
          deleteFunnelStep={deleteFunnelStep}
          moveFunnelStep={moveFunnelStep}
          setTargetFunnelStep={() => setSelectedFunnelStepTargetId(funnelStep.funnelStepId)}
          isTarget={selectedFunnelStepTargetId === funnelStep.funnelStepId}
          setFunnelStepMeta={({ type, title }) =>
            setFunnelSteps((prevSteps) => {
              const updatedSteps = [...prevSteps];
              updatedSteps[index] = {
                ...updatedSteps[index],
                // @ts-ignore
                type,
                title,
              };
              return updatedSteps;
            })
          }
        />
      ))}
      <ConfirmationDialog
        description="Your changes will apply to the active funnel setup in your portfolio."
        onConfirm={handleSubmit}
        type="apply"
        isOpen={isApplyOpen}
        onCancel={closeApplyDialog}
        disabled={upsertLoading || updateFunnelTargetLoading}
      />
      <ConfirmationDialog
        description="Your changes will be discarded. The funnel view will revert to the current active funnel setup in your portfolio."
        onConfirm={() => {
          setFunnelSteps(initialFunnelSteps);
          setSelectedFunnelStepTargetId(defaultTargetFunnelStepId);
          closeDiscardDialog();
        }}
        type="discard"
        isOpen={isDiscardOpen}
        onCancel={closeDiscardDialog}
      />
    </div>
  );
}
