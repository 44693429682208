import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { StringParam } from 'serialize-query-params';
import styled from 'styled-components';
import { BooleanParam, NumberParam, useQueryParam, useQueryParams } from 'use-query-params';

import { NexoyaFunnelStepType } from '../../types';

import { usePortfolio } from 'context/PortfolioProvider';

import { FEATURE_FLAGS } from '../../constants/featureFlags';
import { GLOBAL_DATE_FORMAT } from '../../utils/dates';
import { capitalizeWords } from '../../utils/string';

import PortfolioPerformanceChart from '../../components/Charts/PortfolioPerformanceChart';
import { usePortfolioToPerformanceChartData } from '../../components/Charts/converters/portfolioToPerformanceChartData';
import { DateSelectorProps } from '../../components/DateSelector';
import FeatureSwitch from '../../components/FeatureSwitch';
import MultipleSwitch from '../../components/MultipleSwitchFluid';
import SwitchFluid from '../../components/SwitchFluid';
import { MultiSeriesFunnel } from './components/Funnel/MultiSeriesFunnel';
import { OldPerformanceChartHeader, PerformanceHeader } from './components/PerformanceChartHeader';
import PortfolioChartLegend from './components/PortfolioChartLegend';
import PortfolioCostPerChart from 'components/Charts/PortfolioCostPerChart';

import {
  BricksWrapper,
  LoadingPlaceholderCardStyled,
  LoadingPlaceholderChart,
  LoadingPlaceholderFunnelStyled,
  LoadingPlaceholderFunnelWrapperStyled,
  LoadingPlaceholderWrapperStyled,
  LoadingWrapper,
} from './styles/Portfolio';
import NoData from '../kpi0/NoData';
import { PerformanceTable } from '../../components/PerformanceTable/PerformanceTable';
import { usePerformanceTotalsQuery } from '../../graphql/performance/queryPerformanceTotals';
import { usePerformanceFunnelStepQuery } from '../../graphql/performance/queryPerformanceFunnelStep';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import { SubtitleLoader } from '../KPI';
import { BrickLoader, BrickLoaderWrapper } from '../Portfolio';

type Props = {
  portfolioId: number;
  dateSelectorProps: DateSelectorProps;
  comparisonDateSelectorProps: DateSelectorProps;
  oldPortfolio?: any;
};

const PerformanceContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`;

const ChartContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type PerformanceSwitchType = 'overview' | 'metrics';
const PERFORMANCE_SWITCH_SECTIONS = [
  {
    id: 'overview',
    text: 'Overview',
  },
];

export function Performance({ portfolioId, dateSelectorProps, comparisonDateSelectorProps }: Props) {
  const [switchSections, setSwitchSections] = useState<{ id: string; text: string }[]>([]);
  const [activeSwitch, setActiveSwitch] = useState<PerformanceSwitchType>('overview');
  const [isMounted, setIsMounted] = useState(false);
  const [dateComparisonActive] = useQueryParam('dateComparisonActive', BooleanParam);

  const {
    optimization: { optimizations, setOptimizations },
    selectedFunnelStep: { selectedFunnelStep, setSelectedFunnelStep },
    portfolioV2Info: {
      meta: { data: portfolioMeta },
      funnelSteps: { data: funnelSteps, updateState },
    },
  } = usePortfolio();

  const { data, loading, error } = usePerformanceTotalsQuery({
    funnelStepId: selectedFunnelStep?.funnel_step_id ?? -1,
    period: {
      start: dayjs(dateSelectorProps.dateFrom).utc().format(GLOBAL_DATE_FORMAT),
      end: dayjs(dateSelectorProps.dateTo).utc().format(GLOBAL_DATE_FORMAT),
    },
    comparisonPeriod: dateComparisonActive
      ? {
          start: dayjs(comparisonDateSelectorProps.dateFrom).utc().format(GLOBAL_DATE_FORMAT),
          end: dayjs(comparisonDateSelectorProps.dateTo).utc().format(GLOBAL_DATE_FORMAT),
        }
      : undefined,
    portfolioId,
  });

  const {
    data: funnelStepData,
    loading: funnelStepLoading,
    error: funnelStepError,
  } = usePerformanceFunnelStepQuery({
    funnelStepId: selectedFunnelStep?.funnel_step_id ?? -1,
    period: {
      start: dayjs(dateSelectorProps.dateFrom).utc().format(GLOBAL_DATE_FORMAT),
      end: dayjs(dateSelectorProps.dateTo).utc().format(GLOBAL_DATE_FORMAT),
    },
    comparisonPeriod: dateComparisonActive
      ? {
          start: dayjs(comparisonDateSelectorProps.dateFrom).utc().format(GLOBAL_DATE_FORMAT),
          end: dayjs(comparisonDateSelectorProps.dateTo).utc().format(GLOBAL_DATE_FORMAT),
        }
      : undefined,
    portfolioId,
  });

  const [queryParams, setQueryParams] = useQueryParams({
    fs: NumberParam,
    chart: StringParam,
  });
  const [activeChart, setActiveChart] = useState(queryParams.chart || 'performance');
  const isCostSelected = selectedFunnelStep?.type === NexoyaFunnelStepType.Cost;

  const isPortfolioActive = dayjs().isBefore(dayjs(portfolioMeta?.end));
  const portfolioDashboardUrls =
    portfolioMeta?.portfolioDashboardUrls?.filter((dashboardUrl) => dashboardUrl?.name && dashboardUrl?.url) || [];

  const hasLabels = selectedFunnelStep?.dailyMetrics?.[0].labels?.some((w) => w.labelId);
  const hasMultipleImpactGroups = selectedFunnelStep?.dailyMetrics?.[0].impactGroups?.length > 1;

  const {
    realizedMetricDataPast,
    validationDataFormatted,
    validationTooltip,
    comparisonPerformanceChartData,
    dataForChart: performanceChartData,
  } = usePortfolioToPerformanceChartData(isPortfolioActive, selectedFunnelStep);

  useEffect(() => {
    updateState({ data: data?.portfolioV2?.performance?.funnelSteps, loading, error });
    setOptimizations(data?.portfolioV2?.performance?.optimizations);
  }, [data, loading, error, portfolioId]);

  useEffect(() => {
    setSelectedFunnelStep({
      ...selectedFunnelStep,
      // TODO: This results in a redundancy, having duplicate values in the funnelStep
      ...funnelStepData?.portfolioV2?.performance?.funnelStep,
    });
  }, [funnelStepData, funnelStepLoading, funnelStepError, portfolioId]);

  useEffect(() => {
    setTimeout(() => setIsMounted(true));
  }, []);

  useEffect(() => {
    if (portfolioDashboardUrls?.length) {
      setSwitchSections([
        ...PERFORMANCE_SWITCH_SECTIONS,
        ...portfolioDashboardUrls.map((portfolioDashboardUrl) => ({
          id: portfolioDashboardUrl.name,
          text: portfolioDashboardUrl.name,
        })),
      ]);
    }
  }, [portfolioMeta]);

  useEffect(() => {
    if (selectedFunnelStep?.funnel_step_id) {
      setQueryParams({ fs: selectedFunnelStep?.funnel_step_id });
    }
  }, [selectedFunnelStep]);

  useEffect(() => {
    setActiveChart(queryParams.chart || 'performance');
  }, [queryParams.chart]);

  function handleActiveChart(chart: 'performance' | 'cost-per') {
    setQueryParams({
      chart,
    });
    setActiveChart(chart);
  }

  function handleChartChange(e: 'left' | 'right') {
    if (activeChart === 'performance' && e === 'right') {
      handleActiveChart('cost-per');
    }
    if (activeChart === 'cost-per' && e === 'left') {
      handleActiveChart('performance');
    }
  }

  const renderCharts = isMounted || realizedMetricDataPast?.length || performanceChartData?.length;
  return selectedFunnelStep && !loading ? (
    <>
      <PerformanceContainerStyled>
        {switchSections?.length ? (
          <MultipleSwitch
            style={{ marginBottom: 24 }}
            current={activeSwitch}
            sections={switchSections}
            initial={activeSwitch}
            onToggle={setActiveSwitch}
          />
        ) : null}

        {activeSwitch === 'overview' ? (
          renderCharts ? (
            <>
              <div style={{ display: 'flex', width: '100%' }}>
                <FeatureSwitch
                  features={[FEATURE_FLAGS.PERFORMANCE_NEW_DISABLED]}
                  renderNew={() => null}
                  renderOld={() => <MultiSeriesFunnel performanceFunnelSteps={funnelSteps} />}
                />
                <ChartContainerStyled>
                  <FeatureSwitch
                    features={[FEATURE_FLAGS.PERFORMANCE_NEW_DISABLED]}
                    renderNew={() => (
                      <OldPerformanceChartHeader
                        active={activeChart}
                        title={selectedFunnelStep?.title}
                        showSwitcher={!isCostSelected}
                        onToggle={handleChartChange}
                      />
                    )}
                    renderOld={() => (
                      <PerformanceHeader
                        disabled={funnelStepLoading}
                        shouldRenderCustomization
                        shouldRenderLabelsFilter={hasLabels}
                        shouldRenderImpactGroupsFilter={hasMultipleImpactGroups}
                        activeProviderIds={funnelSteps?.[0]?.metricTotals.providers?.map(
                          (providerMetric) => providerMetric.providerId,
                        )}
                        renderSwitcher={() => {
                          return !isCostSelected ? (
                            <SwitchFluid
                              activeText={capitalizeWords(selectedFunnelStep?.title || '')}
                              inactiveText={`Cost per ${selectedFunnelStep?.title?.toLowerCase()}`}
                              initial={activeChart === 'performance' ? 'left' : 'right'}
                              onToggle={handleChartChange}
                            />
                          ) : null;
                        }}
                      />
                    )}
                  />

                  {funnelStepLoading ? (
                    <LoadingPlaceholderChart style={{ width: '100%' }} />
                  ) : (
                    <>
                      {activeChart === 'performance' ? (
                        <PortfolioPerformanceChart
                          data={dateComparisonActive ? comparisonPerformanceChartData : performanceChartData}
                          validationData={validationDataFormatted}
                          validationTooltip={validationTooltip}
                          portfolioId={portfolioId}
                          portfolioTitle={portfolioMeta?.title}
                          optimizations={optimizations || []}
                        />
                      ) : (
                        <PortfolioCostPerChart />
                      )}
                      <FeatureSwitch
                        features={[FEATURE_FLAGS.PERFORMANCE_NEW_DISABLED]}
                        renderNew={() => null}
                        renderOld={() => (
                          <PortfolioChartLegend active={activeChart} title={selectedFunnelStep?.title} />
                        )}
                      />
                    </>
                  )}
                </ChartContainerStyled>
              </div>
              <PerformanceTable dateSelectorProps={dateSelectorProps} portfolioId={portfolioId} />
            </>
          ) : (
            <NoData />
          )
        ) : null}
        {portfolioMeta?.portfolioDashboardUrls?.length > 0
          ? portfolioMeta.portfolioDashboardUrls.map((portfolioUrl) =>
              activeSwitch === portfolioUrl.name ? (
                <iframe
                  referrerPolicy="no-referrer"
                  sandbox="allow-same-origin allow-scripts"
                  key={portfolioUrl.url}
                  frameBorder="0"
                  src={portfolioUrl.url}
                  title={portfolioUrl.name}
                  width="100%"
                  height="5000px"
                />
              ) : null,
            )
          : null}
      </PerformanceContainerStyled>
    </>
  ) : (
    <FeatureSwitch
      features={[FEATURE_FLAGS.PERFORMANCE_NEW_DISABLED]}
      renderNew={() => (
        <LoadingWrapper style={{ flexDirection: 'column' }}>
          <LoadingPlaceholderWrapperStyled style={{ flexDirection: 'row' }}>
            <LoadingPlaceholderCardStyled />
            <LoadingPlaceholderCardStyled />
            <LoadingPlaceholderCardStyled />
            <LoadingPlaceholderCardStyled />
            <LoadingPlaceholderCardStyled />
          </LoadingPlaceholderWrapperStyled>

          <LoadingPlaceholderChart style={{ width: '100%' }} />
        </LoadingWrapper>
      )}
      renderOld={() => (
        <LoadingWrapper>
          <LoadingPlaceholderWrapperStyled>
            <LoadingPlaceholderCardStyled />
            <LoadingPlaceholderCardStyled />
            <LoadingPlaceholderCardStyled />
            <LoadingPlaceholderCardStyled />
            <LoadingPlaceholderCardStyled />
          </LoadingPlaceholderWrapperStyled>
          <LoadingPlaceholderFunnelWrapperStyled>
            <LoadingPlaceholderFunnelStyled />
            <LoadingPlaceholderFunnelStyled />
            <LoadingPlaceholderFunnelStyled />
            <LoadingPlaceholderFunnelStyled />
            <LoadingPlaceholderFunnelStyled />
          </LoadingPlaceholderFunnelWrapperStyled>
          <LoadingPlaceholderChart />
        </LoadingWrapper>
      )}
    />
  );
}

export const PerformanceLoadingSkeleton = () => (
  <LoadingWrapStyled>
    <HeaderStyled>
      <LoadingStyled>
        <div>
          <TitleLoader />
          <SubtitleLoader />
          <BricksWrapper>
            <BrickLoaderWrapper>
              <AvatarLoader style={{ width: 32, height: 32 }} />
              <div>
                <BrickLoader style={{ width: 100 }} />
                <BrickLoader />
              </div>
            </BrickLoaderWrapper>
            <BrickLoaderWrapper>
              <AvatarLoader style={{ width: 32, height: 32 }} />
              <div>
                <BrickLoader style={{ width: 100 }} />
                <BrickLoader />
              </div>
            </BrickLoaderWrapper>
            <BrickLoaderWrapper>
              <AvatarLoader style={{ width: 32, height: 32 }} />
              <div>
                <BrickLoader style={{ width: 100 }} />
                <BrickLoader />
              </div>
            </BrickLoaderWrapper>
          </BricksWrapper>
        </div>
      </LoadingStyled>
    </HeaderStyled>
  </LoadingWrapStyled>
);

export const LoadingWrapStyled = styled.div`
  & > div:first-child {
    margin-bottom: 50px;
  }

  .section {
    &:nth-child(2) {
      margin-bottom: 50px;
    }

    &:nth-child(2) > div {
      height: 350px;
      opacity: 0.75;
    }

    &:nth-child(3) > div {
      height: 400px;
      opacity: 0.35;
    }
  }
`;

export const HeaderStyled = styled.div`
  flex-direction: row;
  position: relative;
  display: flex;
  align-items: center;
`;

export const LoadingStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;

  & > div:last-child {
    flex: 1;
  }
`;

export const AvatarLoader = styled(LoadingPlaceholder)`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  margin-right: 15px;
`;

export const TitleLoader = styled(LoadingPlaceholder)`
  height: 35px;
  max-width: 650px;
  margin-bottom: 6px;
`;
