import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../../components-ui/AlertDialog';
import ButtonAsync from '../../../../components/ButtonAsync';
import React from 'react';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  type: 'discard' | 'apply';
  description: string;
  disabled?: boolean;
}

export const ConfirmationDialog = ({ type, onConfirm, onCancel, isOpen, description, disabled }: Props) => {
  const title = type === 'discard' ? 'Discard changes?' : 'Apply changes';
  const cta = type === 'discard' ? 'Discard' : 'Apply';

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader className="space-y-3">
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>
            <span className="text-sm font-normal leading-5 text-neutral-400">{description}</span>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>
            <ButtonAsync disabled={disabled} onClick={onCancel} variant="contained" color="secondary" size="small">
              Cancel
            </ButtonAsync>
          </AlertDialogAction>

          <AlertDialogAction>
            <ButtonAsync
              disabled={disabled}
              onClick={onConfirm}
              variant="contained"
              color={type === 'discard' ? 'danger' : 'primary'}
              size="small"
            >
              {cta}
            </ButtonAsync>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
