import { Link } from 'react-router-dom';

import { get } from 'lodash';
import styled from 'styled-components';

import { NexoyaMeasurement } from '../../types/types';
import '../../types/types';

import { useGlobalDate } from '../../context/DateProvider';

import AvatarProvider from '../../components/AvatarProvider';
import ContextEditMenu from '../../components/CustomKpi/ContextEditMenu';
import { DateSelector } from '../../components/DateSelector';
import FavoriteKPI from '../../components/FavoriteKPI';
import HelpCenter from '../../components/HelpCenter/HelpCenter';
import PageHeader from '../../components/PageHeader/PageHeader';
import PageHeaderActions from '../../components/PageHeader/PageHeaderActions';
import PageHeaderDescription from '../../components/PageHeader/PageHeaderDescription';
import PageHeaderIcon from '../../components/PageHeader/PageHeaderIcon';
import PageHeaderTitle from '../../components/PageHeader/PageHeaderTitle';
import BackButton from '../../components/Sidebar/components/BackButton';
import Typography from '../../components/Typography';
import TypographyTranslation from '../../components/TypographyTranslation';
import { AdminLinkIcon, ExternalLinkIcon } from '../../components/icons';
import { buildContentPath } from 'routes/paths';

const IconLinkStyled = styled.a`
  padding: 8px;
  color: #797b7c;
  transition: color 0.175s;
  font-size: 18px;

  &:hover {
    color: ${({ theme }: any) => theme.colors.primary};
  }
`;
type Props = {
  isCustomKpi?: boolean;
  measurement: NexoyaMeasurement;
  refetchKpi: () => void;
};

const KpiHeader = ({ isCustomKpi, measurement: kpi, refetchKpi }: Props) => {
  const { from, to, setDateRangeChange } = useGlobalDate();
  if (!kpi) {
    return null;
  }
  const collection = kpi.collection;
  const parentCollection = get(kpi, 'collection.parent_collection', null);
  const collectionTitle = get(kpi, 'collection.title', null);
  const collectionType = get(kpi, 'collection.collectionType.name', '');
  return (
    <PageHeader>
      <div>
        <BackButton />
        <PageHeaderTitle data-cy="kpiHeaderTitle">
          <PageHeaderIcon>
            <AvatarProvider providerId={kpi.provider_id} size={32} />
          </PageHeaderIcon>
          <TypographyTranslation
            variant="h1"
            component="h2"
            text={kpi.name}
            style={{
              marginRight: 12,
            }}
          />
          {kpi.helpcenter_link && <HelpCenter url={kpi.helpcenter_link} />}
          <FavoriteKPI kpi={kpi} refetch={refetchKpi} showMode={false} />
          {collection.url ? (
            <IconLinkStyled
              target="_blank"
              rel="noopener noreferrer"
              href={collection.url}
              // @ts-expect-error
              alt={collection.title}
              title="Website link"
              data-cy="websiteLink"
            >
              <ExternalLinkIcon />
            </IconLinkStyled>
          ) : null}
          {collection.admin_url ? (
            <IconLinkStyled
              target="_blank"
              rel="noopener noreferrer"
              href={collection.admin_url}
              // @ts-expect-error
              alt={collection.title}
              title="Administration website"
              data-cy="adminWebsiteLink"
            >
              <AdminLinkIcon />
            </IconLinkStyled>
          ) : null}
        </PageHeaderTitle>
        <PageHeaderDescription addTitleIconSpace data-cy="kpiHeaderDescription">
          {parentCollection ? (
            <Link to={buildContentPath(parentCollection?.collection_id)}>
              <TypographyTranslation variant="h2" text={parentCollection.title} withTooltip />
            </Link>
          ) : null}
          <Link to={buildContentPath(collection?.collection_id)} style={{ display: 'flex' }}>
            <TypographyTranslation
              variant="h4"
              text={collectionTitle}
              withEllipsis
              withTooltip
              style={{
                marginBottom: 12,
                marginRight: 5,
              }}
            />
            {collectionType && <Typography variant="h4">{`(${collectionType})`}</Typography>}
          </Link>
          <TypographyTranslation withEllipsis={false} variant="subtitle" text={kpi.description} />
        </PageHeaderDescription>
      </div>
      <PageHeaderActions>
        <DateSelector
          dateFrom={from}
          dateTo={to}
          onDateChange={setDateRangeChange}
          hideFutureQuickSelection
          data-cy="kpiHeaderDateSelectorBtn"
        />
        {isCustomKpi && <ContextEditMenu kpi={kpi} />}
      </PageHeaderActions>
    </PageHeader>
  );
};

export default KpiHeader;
