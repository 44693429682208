import React from 'react';

import styled from 'styled-components';

import Typography from '../../components/Typography';

import Button from '../Button';

type Props = {
  handleClose: () => void;
};
const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 32px 32px;
  width: 455px;
`;
const ActionsStyled = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 32px;
`;

function ErrorMessage({ handleClose }: Props) {
  return (
    <>
      <ContentStyled>
        <span
          role="img"
          aria-label="wearing-face emoji"
          style={{
            fontSize: 48,
          }}
        >
          😩
        </span>
        <Typography
          variant="h1"
          component="h2"
          style={{
            marginBottom: 8,
          }}
        >
          Oh snap!
        </Typography>
        <Typography
          variant="subtitle"
          style={{
            textAlign: 'center',
          }}
          withEllipsis={false}
        >
          Something went wrong. Please try again a bit later...
        </Typography>
      </ContentStyled>
      <ActionsStyled>
        <Button onClick={handleClose} variant="contained">
          Close
        </Button>
      </ActionsStyled>
    </>
  );
}

export default ErrorMessage;
