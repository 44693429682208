import { get } from 'lodash';

import { useTranslationsQuery } from '../graphql/translation/queryTranslations';

export const getNoTranslationString = (string: string): string => string.slice(string.indexOf('}') + 1);

function useTranslation() {
  const { data, loading, error } = useTranslationsQuery();

  if (loading || error) {
    throw new Error('You need to fetch translations at the beginning');
  }

  const translations = get(data, 'translations', []);

  function translate(key: string | null | undefined = ''): string {
    const k = key || 'No data';

    for (const t of translations) {
      if (k === t.key) {
        if (!t.text) {
          return k;
        }

        return t.text;
      }
    }

    return getNoTranslationString(k);
  }

  return {
    translations,
    translate,
  };
}

export { useTranslation };
