import React from 'react';

import dayjs from 'dayjs';
import get from 'lodash.get';
import styled from 'styled-components';

import { NexoyaPortfolioFeatureFlag } from '../../types';

import { useTeamQuery } from '../../graphql/team/queryTeam';

import { GLOBAL_DATE_FORMAT } from '../../utils/dates';

import PortfolioHeaderMenu from '../../routes/portfolio/PortfolioHeaderMenu';

import { PageHeaderActions } from '../PageHeader';
import { Info } from 'lucide-react';
import { useUserQuery } from '../../graphql/user/queryUser';
import { usePortfolioV2MetaBudgetQuery } from '../../graphql/portfolio/queryPortfolioMeta';
import { usePortfolio } from '../../context/PortfolioProvider';
import PortfolioFeatureSwitch from '../PortfolioFeatureSwitch';
import { PORTFOLIO_FEATURE_FLAGS } from '../../constants/featureFlags';
import { PortfolioHeaderTitle } from './PortfolioHeaderTitle';
import { PortfolioBricks } from './PortfolioBricks';
import { cn } from '../../lib/utils';
import { PartialFunnelStep } from '../../controllers/PortfolioController';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .NEXYPageHeaderActions {
    margin-left: 0;
    margin-top: 0;
  }

  .NEXYPageHeader {
    margin-bottom: 0;
  }
`;

const PortfolioHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const PortfolioMenu = ({
  portfolioId,
  selectedFunnelStep,
  dateFrom,
  dateTo,
  navigationV2Enabled,
}: {
  portfolioId: number;
  dateFrom: Date;
  dateTo: Date;
  selectedFunnelStep: PartialFunnelStep;
  navigationV2Enabled: boolean;
}) => {
  const {
    portfolioV2Info: {
      meta: { data: portfolioMeta, loading },
    },
  } = usePortfolio();

  const { data: portfolioMetaBudgetData } = usePortfolioV2MetaBudgetQuery({
    start: dayjs(dateFrom).utc().format(GLOBAL_DATE_FORMAT),
    end: dayjs(dateTo).utc().format(GLOBAL_DATE_FORMAT),
    portfolioId,
  });

  const teamQuery = useTeamQuery({
    withMembers: false,
    withOrg: false,
  });
  const { data: userData } = useUserQuery();

  const currency = get(teamQuery, 'data.team.currency', 'CHF') || 'CHF';
  const numberFormat = get(teamQuery, 'data.team.number_format', 'de-CH') || 'de-CH';

  const isSupportUser = userData?.user?.activeRole?.name?.includes('support');

  const targetFunnelStepTitle = portfolioMeta?.defaultOptimizationTarget?.title || selectedFunnelStep?.title || '';
  const portfolioMetaBudget = portfolioMetaBudgetData?.portfolioV2?.budget;
  const portfolioFeatureFlags = portfolioMeta?.featureFlags || [];
  const onlyVisibleToSupportUsers = portfolioFeatureFlags.some(
    (flag) => flag.name === NexoyaPortfolioFeatureFlag.OptimizationsOnlyVisibleToSupportUsers && flag.status,
  );

  return !loading ? (
    <PortfolioHeaderWrapper className={navigationV2Enabled ? '' : 'w-full px-[48px] pb-4 pt-[32px]'}>
      <HeaderWrapper>
        <PortfolioFeatureSwitch
          features={[PORTFOLIO_FEATURE_FLAGS.NAVIGATION_V2]}
          renderNew={() => null}
          renderOld={() => (
            <PortfolioHeaderTitle title={portfolioMeta?.title} description={portfolioMeta?.description} />
          )}
        />

        <PageHeaderActions>
          {onlyVisibleToSupportUsers && isSupportUser ? (
            <div
              className={cn(
                'flex items-center justify-center gap-1.5 rounded-md border border-[#B9E7FE] bg-aliceBlue px-6 py-3',
                navigationV2Enabled ? 'max-h-[34px]' : 'max-h-10',
              )}
            >
              <Info className="h-5 w-5 fill-[#05A8FA] text-white" />
              <span className="text-sm text-charcoalGrey">Preview optimization for support only</span>
            </div>
          ) : null}

          <PortfolioHeaderMenu portfolio={portfolioMeta} navigationV2Enabled={navigationV2Enabled} />
        </PageHeaderActions>
      </HeaderWrapper>

      <PortfolioFeatureSwitch
        features={[PORTFOLIO_FEATURE_FLAGS.NAVIGATION_V2]}
        renderNew={() => null}
        renderOld={() => (
          <PortfolioBricks
            portfolio={{ ...portfolioMeta, budget: portfolioMetaBudget }}
            currency={currency}
            numberFormat={numberFormat}
            funnelStepTitle={targetFunnelStepTitle}
          />
        )}
      />
    </PortfolioHeaderWrapper>
  ) : null;
};

export default PortfolioMenu;
